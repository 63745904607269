var exports = {};

function joinPath(pathArray) {
  if (pathArray.length > 0) {
    return pathArray.join("/") + "/";
  } else {
    return "";
  }
}

function resolveDotSegments(pathArray) {
  var pathAbsolute = [];
  pathArray.forEach(function (dir) {
    if (dir !== "..") {
      if (dir !== ".") {
        pathAbsolute.push(dir);
      }
    } else {
      // Remove parent
      if (pathAbsolute.length > 0) {
        pathAbsolute.splice(pathAbsolute.length - 1, 1);
      }
    }
  });
  return pathAbsolute;
}

exports = {
  join: joinPath,
  resolveDotSegments: resolveDotSegments
};
export default exports;