import _absolutize from "./absolutize";
import _relativize from "./relativize";
var exports = {};
var absolutize = _absolutize;
var relativize = _relativize;

function relateUrl(siteUrlObj, urlObj, options) {
  absolutize(urlObj, siteUrlObj, options);
  relativize(urlObj, siteUrlObj, options);
  return urlObj;
}

exports = relateUrl;
export default exports;