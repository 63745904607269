import _hrefInfo from "./hrefInfo";
import _host from "./host";
import _path from "./path";
import _port from "./port";
import _query from "./query";
import _urlstring from "./urlstring";
import _path2 from "../util/path";
var exports = {};
var hrefInfo = _hrefInfo;
var parseHost = _host;
var parsePath = _path;
var parsePort = _port;
var parseQuery = _query;
var parseUrlString = _urlstring;
var pathUtils = _path2;

function parseFromUrl(url, options, fallback) {
  if (url) {
    var urlObj = parseUrl(url, options); // Because the following occurs in the relate stage for "to" URLs,
    // such had to be mostly duplicated here

    var pathArray = pathUtils.resolveDotSegments(urlObj.path.absolute.array);
    urlObj.path.absolute.array = pathArray;
    urlObj.path.absolute.string = "/" + pathUtils.join(pathArray);
    return urlObj;
  } else {
    return fallback;
  }
}

function parseUrl(url, options) {
  var urlObj = parseUrlString(url, options);
  if (urlObj.valid === false) return urlObj;
  parseHost(urlObj, options);
  parsePort(urlObj, options);
  parsePath(urlObj, options);
  parseQuery(urlObj, options);
  hrefInfo(urlObj);
  return urlObj;
}

exports = {
  from: parseFromUrl,
  to: parseUrl
};
export default exports;