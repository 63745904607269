var exports = {};

function parseHost(urlObj, options) {
  // TWEAK :: condition only for speed optimization
  if (options.ignore_www) {
    var host = urlObj.host.full;

    if (host) {
      var stripped = host;

      if (host.indexOf("www.") === 0) {
        stripped = host.substr(4);
      }

      urlObj.host.stripped = stripped;
    }
  }
}

exports = parseHost;
export default exports;