import _constants from "./constants";
var exports = {};
var constants = _constants;

function formatAuth(urlObj, options) {
  if (urlObj.auth && !options.removeAuth && (urlObj.extra.relation.maximumHost || options.output === constants.ABSOLUTE)) {
    return urlObj.auth + "@";
  }

  return "";
}

function formatHash(urlObj, options) {
  return urlObj.hash ? urlObj.hash : "";
}

function formatHost(urlObj, options) {
  if (urlObj.host.full && (urlObj.extra.relation.maximumAuth || options.output === constants.ABSOLUTE)) {
    return urlObj.host.full;
  }

  return "";
}

function formatPath(urlObj, options) {
  var str = "";
  var absolutePath = urlObj.path.absolute.string;
  var relativePath = urlObj.path.relative.string;
  var resource = showResource(urlObj, options);

  if (urlObj.extra.relation.maximumHost || options.output === constants.ABSOLUTE || options.output === constants.ROOT_RELATIVE) {
    str = absolutePath;
  } else if (relativePath.length <= absolutePath.length && options.output === constants.SHORTEST || options.output === constants.PATH_RELATIVE) {
    str = relativePath;

    if (str === "") {
      var query = showQuery(urlObj, options) && !!getQuery(urlObj, options);

      if (urlObj.extra.relation.maximumPath && !resource) {
        str = "./";
      } else if (urlObj.extra.relation.overridesQuery && !resource && !query) {
        str = "./";
      }
    }
  } else {
    str = absolutePath;
  }

  if (str === "/" && !resource && options.removeRootTrailingSlash && (!urlObj.extra.relation.minimumPort || options.output === constants.ABSOLUTE)) {
    str = "";
  }

  return str;
}

function formatPort(urlObj, options) {
  if (urlObj.port && !urlObj.extra.portIsDefault && urlObj.extra.relation.maximumHost) {
    return ":" + urlObj.port;
  }

  return "";
}

function formatQuery(urlObj, options) {
  return showQuery(urlObj, options) ? getQuery(urlObj, options) : "";
}

function formatResource(urlObj, options) {
  return showResource(urlObj, options) ? urlObj.resource : "";
}

function formatScheme(urlObj, options) {
  var str = "";

  if (urlObj.extra.relation.maximumHost || options.output === constants.ABSOLUTE) {
    if (!urlObj.extra.relation.minimumScheme || !options.schemeRelative || options.output === constants.ABSOLUTE) {
      str += urlObj.scheme + "://";
    } else {
      str += "//";
    }
  }

  return str;
}

function formatUrl(urlObj, options) {
  var url = "";
  url += formatScheme(urlObj, options);
  url += formatAuth(urlObj, options);
  url += formatHost(urlObj, options);
  url += formatPort(urlObj, options);
  url += formatPath(urlObj, options);
  url += formatResource(urlObj, options);
  url += formatQuery(urlObj, options);
  url += formatHash(urlObj, options);
  return url;
}

function getQuery(urlObj, options) {
  var stripQuery = options.removeEmptyQueries && urlObj.extra.relation.minimumPort;
  return urlObj.query.string[stripQuery ? "stripped" : "full"];
}

function showQuery(urlObj, options) {
  return !urlObj.extra.relation.minimumQuery || options.output === constants.ABSOLUTE || options.output === constants.ROOT_RELATIVE;
}

function showResource(urlObj, options) {
  var removeIndex = options.removeDirectoryIndexes && urlObj.extra.resourceIsIndex;
  var removeMatchingResource = urlObj.extra.relation.minimumResource && options.output !== constants.ABSOLUTE && options.output !== constants.ROOT_RELATIVE;
  return !!urlObj.resource && !removeMatchingResource && !removeIndex;
}

exports = formatUrl;
export default exports;