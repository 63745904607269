var exports = {};

function hrefInfo(urlObj) {
  var minimumPathOnly = !urlObj.scheme && !urlObj.auth && !urlObj.host.full && !urlObj.port;
  var minimumResourceOnly = minimumPathOnly && !urlObj.path.absolute.string;
  var minimumQueryOnly = minimumResourceOnly && !urlObj.resource;
  var minimumHashOnly = minimumQueryOnly && !urlObj.query.string.full.length;
  var empty = minimumHashOnly && !urlObj.hash;
  urlObj.extra.hrefInfo.minimumPathOnly = minimumPathOnly;
  urlObj.extra.hrefInfo.minimumResourceOnly = minimumResourceOnly;
  urlObj.extra.hrefInfo.minimumQueryOnly = minimumQueryOnly;
  urlObj.extra.hrefInfo.minimumHashOnly = minimumHashOnly;
  urlObj.extra.hrefInfo.empty = empty;
}

exports = hrefInfo;
export default exports;